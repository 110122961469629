import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import {
  type BackendErrorResponse,
  CalendarEventAttendeeType,
  CalendarEventSource,
  CalendarEventType,
  Colour,
  type ParsedErrorDetail,
  RecurrenceEnum,
} from '@tyro/api';
import {
  ConfirmDialog,
  Dialog,
  DialogActions,
  DialogTitle,
  RHFAutocomplete,
  RHFColorPicker,
  RHFTextField,
  type ValidationError,
  useDisclosure,
  useFormValidator,
  useToast,
  validations,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { usePartySearchProps } from '@tyro/people';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useCreateCalendarEvent } from '../../../../api/add-event';
import { useEditCalendarEvents } from '../../../../api/edit-events';
import type { CalendarParty } from '../../../../hooks/use-calendar-search-props';
import { MINIMUM_EVENT_DURATION } from './constants';
import { useGetRecurrenceFilter } from './hooks/use-get-recurrence-filter';
import { RoomLocationOptions } from './room-location-options';
import { ScheduleEvent } from './schedule-event';
import type { FormState } from './types';

export type CalendarEventViewProps = {
  isOpen: boolean;
  initialState?: Partial<FormState> | null;
  onClose: () => void;
};

export const CalendarEventDetailsModal = ({
  isOpen,
  initialState,
  onClose,
}: CalendarEventViewProps) => {
  const { t } = useTranslation(['calendar', 'common']);
  const { toast } = useToast();

  const {
    isOpen: isConfirmClashOpen,
    onOpen: openConfirmClash,
    onClose: closeConfirmClash,
  } = useDisclosure();

  const participantsProps = usePartySearchProps();

  const { mutateAsync: createEvent, isPending: isCreateSubmitting } =
    useCreateCalendarEvent();

  const { mutateAsync: editEvent, isPending: isEditSubmitting } =
    useEditCalendarEvents();

  const isSubmitting = isCreateSubmitting || isEditSubmitting;

  const { resolver, rules } = useFormValidator<FormState>();

  const defaultFormStateValues: Partial<FormState> = {
    startDate: dayjs(),
    startTime: dayjs(),
    endTime: dayjs().add(MINIMUM_EVENT_DURATION, 'minutes'),
    recurrenceEnum: RecurrenceEnum.NoRecurrence,
    colour: Colour.Red,
    allowClashes: false,
  };

  const { control, handleSubmit, watch, reset, setValue } = useForm<FormState>({
    resolver: resolver({
      name: rules.required(),
      allDayEvent: rules.required(),
      startDate: [rules.date(), rules.required()],
      startTime: [
        rules.required(),
        rules.date(t('common:errorMessages.invalidTime')),
      ],
      endTime: [
        rules.required(),
        rules.date(t('common:errorMessages.invalidTime')),
        rules.afterStartDate(
          'startTime',
          t('common:errorMessages.afterStartTime'),
        ),
        rules.validate<FormState['endTime']>(
          (endTime, throwError, { startTime }) => {
            if (
              dayjs(endTime).diff(startTime, 'minutes') < MINIMUM_EVENT_DURATION
            ) {
              throwError(
                t('calendar:errorMessages.minEventDuration', {
                  time: MINIMUM_EVENT_DURATION,
                }),
              );
            }
          },
        ),
      ],
      recurrenceEnum: rules.required(),
      ends: rules.required(),
      occurrences: rules.validate<FormState['occurrences']>(
        (occurrences, throwError, { ends }) => {
          if (ends === 'after') {
            try {
              validations.required(
                occurrences,
                t('common:errorMessages.required'),
              );
              validations.min(
                occurrences ?? 0,
                1,
                t('common:errorMessages.min', { number: 1 }),
              );
            } catch (error) {
              throwError((error as ValidationError).message);
            }
          }
        },
      ),
      endDate: [
        rules.date(),
        rules.afterStartDate('startDate'),
        rules.validate<FormState['endDate']>(
          (endDate, throwError, { ends }) => {
            if (ends === 'on') {
              try {
                validations.required(
                  endDate,
                  t('common:errorMessages.required'),
                );
              } catch (error) {
                throwError((error as ValidationError).message);
              }
            }
          },
        ),
      ],
      participants: rules.required(),
      colour: rules.required(),
    }),
    defaultValues: defaultFormStateValues,
  });

  useEffect(() => {
    if (initialState) {
      reset({
        ...defaultFormStateValues,
        ...initialState,
      });
    }
  }, [initialState]);

  const [
    allDayEvent,
    startDate,
    startTime,
    endTime,
    recurrenceEnum,
    occurrences,
    endDate,
  ] = watch([
    'allDayEvent',
    'startDate',
    'startTime',
    'endTime',
    'recurrenceEnum',
    'occurrences',
    'endDate',
  ]);

  const recurrenceFilter = useGetRecurrenceFilter({
    allDayEvent,
    startDate,
    startTime,
    endTime,
    recurrenceEnum,
    occurrences,
    endDate,
  });

  const onSubmit = handleSubmit(
    async ({
      participants,
      locations,
      allowClashes,
      calendarId,
      eventId,
      name,
      description,
      colour,
      recurrenceEnum,
      allDayEvent,
      startDate,
      startTime,
      endDate,
      endTime,
    }) => {
      if (!recurrenceFilter) return;

      try {
        if (eventId && calendarId) {
          const isSingle = recurrenceEnum === RecurrenceEnum.NoRecurrence;

          await editEvent({
            calendarId,
            editSource: CalendarEventSource.Manual,
            events: [
              {
                eventId,
                name,
                description,
                colour,
                schedule: {
                  startDate: startDate.format('YYYY-MM-DD'),
                  startTime: startTime.format('HH:mm'),
                  endTime: endTime.format('HH:mm'),
                  endDate:
                    isSingle || !endDate ? null : endDate.format('YYYY-MM-DD'),
                  recurrenceEnum: isSingle ? null : recurrenceEnum,
                },
                attendees: participants.map(({ partyId, attendeeType }) => ({
                  partyId,
                  type: attendeeType ?? CalendarEventAttendeeType.Attendee,
                  tags: [],
                })),
                rooms: locations.map(({ roomId }) => ({
                  roomId,
                  tags: [],
                })),
              },
            ],
          });
        } else {
          await createEvent({
            allowClashes,
            events: [
              {
                name,
                description,
                colour,
                type: CalendarEventType.General,
                recurrenceEnum,
                allDayEvent,
                startDate: recurrenceFilter.fromDate,
                startTime: recurrenceFilter.startTime,
                endTime: recurrenceFilter.endTime,
                endDate: recurrenceFilter.endDate ?? null,
                occurrences: recurrenceFilter.occurrences ?? null,
                attendees: participants.map(({ partyId, attendeeType }) => ({
                  partyId,
                  type: attendeeType ?? CalendarEventAttendeeType.Attendee,
                  // TODO: remove when tags are non mandatory
                  tags: [],
                })),
                rooms: locations.map(({ roomId }) => ({
                  roomId,
                  // TODO: remove when tags are non mandatory
                  tags: [],
                })),
                // TODO: remove when tags are non mandatory
                tags: [],
              },
            ],
          });
        }
      } catch (error: unknown) {
        let errorMessage = t('common:snackbarMessages.errorFailed');

        if (typeof error === 'object' && error !== null) {
          const backendError = error as BackendErrorResponse;
          try {
            const parsedError = JSON.parse(
              backendError.response.error,
            ) as ParsedErrorDetail;
            if (parsedError.detail.includes('clash')) {
              openConfirmClash();
              return;
            }

            errorMessage = parsedError.detail || errorMessage;
          } catch (parseError) {
            console.error('Error parsing the error message:', parseError);
          }
        }

        toast(errorMessage, { variant: 'error' });
      } finally {
        onClose();
      }
    },
  );

  const isEditing = !!initialState?.eventId;

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle onClose={onClose}>
          {isEditing ? t('calendar:editEvent') : t('calendar:addEvent')}
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <Stack gap={1.5} sx={{ p: 3 }}>
            <RHFTextField
              label={t('calendar:inputLabels.title')}
              controlProps={{
                name: 'name',
                control,
              }}
            />

            <ScheduleEvent control={control} isEditing={isEditing} />

            <RHFAutocomplete<FormState, CalendarParty, true>
              {...participantsProps}
              controlProps={{
                name: 'participants',
                control,
              }}
            />

            <RoomLocationOptions
              recurrenceFilter={recurrenceFilter}
              control={control}
            />

            <RHFTextField
              label={t('calendar:inputLabels.description')}
              controlProps={{
                name: 'description',
                control,
              }}
              textFieldProps={{
                multiline: true,
                rows: 4,
              }}
            />

            <RHFColorPicker
              label={t('calendar:inputLabels.eventColor')}
              controlProps={{
                name: 'colour',
                control,
              }}
            />
          </Stack>

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {isEditing ? t('common:actions.save') : t('common:actions.add')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmDialog
        open={isConfirmClashOpen}
        title={t('calendar:clashDetected')}
        description={t('calendar:clashDetectedWouldYouLikeToContinue')}
        confirmText={t('common:actions.continue')}
        onClose={closeConfirmClash}
        onConfirm={() => {
          setValue('allowClashes', true);
          return onSubmit();
        }}
      />
    </>
  );
};
