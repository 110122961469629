import {
  Forms_LinkEntityType,
  GroupTagType,
  SearchType,
  TtSearchType,
  Tt_GroupType,
  queryClient,
} from '@tyro/api';
import {
  type NavObjectFunction,
  NavObjectType,
  ProcessingDataPlaceholder,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { getSearchFormsSubmissions } from '@tyro/forms';
import { getCatalogueGroupTags } from '@tyro/groups';
import { EditCalendarIcon } from '@tyro/icons';
import { redirect } from 'react-router-dom';
import { getTtBlocksList } from './api/common/blocks-list';
import { getTimetableGrids } from './api/common/grids';
import { getTimetable } from './api/common/timetable';
import { getTimetables } from './api/common/timetables';
import { getTimetableGroups } from './api/common/tt-groups';
import { getIndividualLessons } from './api/construction/curriculum/lessons';
import { getViewClassData } from './api/construction/curriculum/view-class-data';
import { getRestrictions } from './api/construction/restrictions';
import { getRestrictionsMeta } from './api/construction/restrictions-meta';
import { getRoomAssignments } from './api/construction/rooms/assignments';
import { getScheduleView } from './api/construction/schedule/schedule-view';
import { getTtSolveStatus } from './api/construction/solve';
import { getSubjectCompetencies } from './api/construction/subject-competencies';
import { getSubjectCompetenciesMeta } from './api/construction/subject-competencies-meta';
import { getTeacherAssignments } from './api/construction/teacher/assignments';
import { getTimetableResourceView } from './api/edit-timetable/resource-view';
import { timetableKeys } from './api/keys';

const TimetableConstructionList = lazyWithRetry(
  () => import('./pages/construction'),
);
// Main construction container
const TimetableConstructionContainer = lazyWithRetry(
  () => import('./components/construction/container'),
);

// Construction sub containers
const TimetableConstructionSetupContainer = lazyWithRetry(
  () => import('./components/construction/set-up/container'),
);
const TimetableConstructionClassDataContainer = lazyWithRetry(
  () => import('./components/construction/curriculum/container'),
);
const TimetableConstructionTeacherTabContainer = lazyWithRetry(
  () => import('./components/construction/teacher/container'),
);
const TimetableConstructionConstructionTabContainer = lazyWithRetry(
  () => import('./components/construction/schedule/container'),
);

// Construction pages

// Set up sub-tabs
const ConstructionGridTab = lazyWithRetry(
  () => import('./pages/construction/set-up/grid'),
);
const ConstructionRestrictionsTab = lazyWithRetry(
  () => import('./pages/construction/set-up/rules'),
);

// Curriculum sub-tabs
const ConstructionBlocksAndCoresTab = lazyWithRetry(
  () => import('./pages/construction/curriculum/block-and-core'),
);
const ConstructionMainstreamGroups = lazyWithRetry(
  () => import('./pages/construction/curriculum/mainstream'),
);
const ConstructionFreeformGroups = lazyWithRetry(
  () => import('./pages/construction/curriculum/freeform'),
);
const ConstructionNonTeachingGroups = lazyWithRetry(
  () => import('./pages/construction/curriculum/non-teaching'),
);
const ConstructionLessons = lazyWithRetry(
  () => import('./pages/construction/curriculum/lessons'),
);

// Teacher sub-tabs
const ConstructionTeacherAssignmentsTab = lazyWithRetry(
  () => import('./pages/construction/teacher/assignments'),
);
const ConstructionCompetenciesTab = lazyWithRetry(
  () => import('./pages/construction/teacher/competencies'),
);

// Schedule tab
const ConstructionScheduleOverviewTab = lazyWithRetry(
  () => import('./pages/construction/schedule/overview'),
);
const ConstructionScheduleTab = lazyWithRetry(
  () => import('./pages/construction/schedule/schedule'),
);

// Rooms tab
const ConstructionRoomsAssignmentsTab = lazyWithRetry(
  () => import('./pages/construction/rooms/assignments'),
);

// Review tab
const ConstructionReviewTimetable = lazyWithRetry(
  () => import('./pages/construction/review'),
);

// Print tabs
const TimetableConstructionPrintTabContainer = lazyWithRetry(
  () => import('./components/construction/print/container'),
);
const StaffTimetable = lazyWithRetry(
  () => import('./pages/construction/print/staff'),
);
const PrintYearGroupTimetable = lazyWithRetry(
  () => import('./pages/construction/print/year-timetable'),
);
const PrintClassGroupTimetable = lazyWithRetry(
  () => import('./pages/construction/print/class-timetable'),
);
const PrintRoomTimetable = lazyWithRetry(
  () => import('./pages/construction/print/rooms-timetable'),
);

// Edit timetable

const EditTimetableContainer = lazyWithRetry(
  () => import('./components/edit-timetable/container'),
);
const Timetable = lazyWithRetry(
  () => import('./pages/edit-timetable/timetable'),
);
const TimetableSubjectGroups = lazyWithRetry(
  () => import('./pages/edit-timetable/groups'),
);

// On call duty

const OnCallDutyContainer = lazyWithRetry(
  () => import('./components/on-call-duty/container'),
);

const OnCall = lazyWithRetry(() => import('./pages/on-call-duty/on-call'));
const Duty = lazyWithRetry(() => import('./pages/on-call-duty/duty'));
const OnCallDutyGroups = lazyWithRetry(
  () => import('./pages/on-call-duty/groups'),
);

const OnCallDutyAssignments = lazyWithRetry(
  () => import('./pages/on-call-duty/assignments'),
);

const OnCallForms = lazyWithRetry(() => import('./pages/on-call-duty/forms'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:management.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        icon: <EditCalendarIcon />,
        title: t('navigation:management.timetable.title'),
        path: 'timetable',
        hasAccess: (permissions) =>
          permissions.hasPermission(
            'ps:1:timetable_construction:view_timetable_edit',
          ),
        children: [
          {
            type: NavObjectType.MenuLink,
            title: t('navigation:management.timetable.construction'),
            path: 'construction',
            hasAccess: ({ hasPermission }) =>
              hasPermission(
                'ps:1:timetable_construction:tt_construction_beta_test',
              ),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                element: <TimetableConstructionList />,
                loader: async () => getTimetables({}),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':timetableId',
                loader: ({ params }) => {
                  const timetableId = getNumber(params?.timetableId);
                  if (!timetableId) {
                    throw404Error();
                  }
                  return getTimetable({ timetableId });
                },
                element: <TimetableConstructionContainer />,
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./set-up'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'set-up',
                    element: <TimetableConstructionSetupContainer />,
                    children: [
                      {
                        type: NavObjectType.NonMenuLink,
                        index: true,
                        loader: () => redirect('./grid'),
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'grid',
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);
                          if (!timetableId) {
                            throw404Error();
                          }

                          return Promise.all([
                            getTimetableGrids({ timetableId }),
                            getCatalogueGroupTags({
                              tagTypes: [
                                GroupTagType.ProgrammeStage,
                                GroupTagType.SupportGrouping,
                                GroupTagType.Meeting,
                              ],
                            }),
                          ]);
                        },
                        element: <ConstructionGridTab />,
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'rules',
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);
                          if (!timetableId) {
                            throw404Error();
                          }

                          return Promise.all([
                            getRestrictionsMeta({ timetableId }),
                            getRestrictions({ timetableId }),
                            getTimetableGrids({ timetableId }),
                          ]);
                        },
                        element: <ConstructionRestrictionsTab />,
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'weightings',
                        element: <ProcessingDataPlaceholder />,
                        hasAccess: ({ isTyroUser }) => isTyroUser,
                      },
                    ],
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'curriculum',
                    element: <TimetableConstructionClassDataContainer />,
                    children: [
                      {
                        type: NavObjectType.NonMenuLink,
                        index: true,
                        loader: () => redirect('./blocks-and-cores'),
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'blocks-and-cores',
                        element: <ConstructionBlocksAndCoresTab />,
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);

                          if (!timetableId) {
                            throw404Error();
                          }

                          const { catalogue_groupTags: groupTags } =
                            await getCatalogueGroupTags({
                              tagTypes: [GroupTagType.ProgrammeStage],
                            });

                          return getViewClassData({
                            timetableId,
                            groupTags: [groupTags[0]?.id ?? 0],
                          });
                        },
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'mainstream',
                        element: <ConstructionMainstreamGroups />,
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);

                          if (!timetableId) {
                            throw404Error();
                          }

                          getTtBlocksList({ timetableId });
                          const { catalogue_groupTags: groupTags } =
                            await getCatalogueGroupTags({
                              tagTypes: [
                                GroupTagType.YearGroup,
                                GroupTagType.ProgrammeStage,
                              ],
                            });

                          return getTimetableGroups({
                            timetableId,
                            groupTags: [groupTags[0]?.id ?? 0],
                          });
                        },
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'freeform',
                        element: <ConstructionFreeformGroups />,
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);

                          if (!timetableId) {
                            throw404Error();
                          }

                          const { catalogue_groupTags: groupTags } =
                            await getCatalogueGroupTags({
                              tagTypes: [GroupTagType.SupportGrouping],
                            });

                          if (!groupTags.length) return;

                          return getTimetableGroups({
                            timetableId,
                            groupTags: [groupTags[0]?.id ?? 0],
                          });
                        },
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'non-teaching',
                        element: <ConstructionNonTeachingGroups />,
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);

                          if (!timetableId) {
                            throw404Error();
                          }

                          return getTimetableGroups({
                            timetableId,
                            groupTags: [],
                            groupTypes: [Tt_GroupType.NonStudent],
                          });
                        },
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'lessons',
                        element: <ConstructionLessons />,
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);

                          if (!timetableId) {
                            throw404Error();
                          }

                          return getIndividualLessons({
                            timetableId,
                          });
                        },
                      },
                    ],
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'teacher',
                    element: <TimetableConstructionTeacherTabContainer />,
                    children: [
                      {
                        type: NavObjectType.NonMenuLink,
                        index: true,
                        loader: () => redirect('./assignments'),
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'assignments',
                        element: <ConstructionTeacherAssignmentsTab />,
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);

                          if (!timetableId) {
                            throw404Error();
                          }

                          return getTeacherAssignments({ timetableId });
                        },
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'competencies',
                        element: <ConstructionCompetenciesTab />,
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);

                          if (!timetableId) {
                            throw404Error();
                          }

                          const { ttsolve_competenciesMeta: competenciesData } =
                            await getSubjectCompetenciesMeta();

                          return getSubjectCompetencies({
                            timetableId,
                            competencyGroupId:
                              competenciesData?.groups?.[0]?.id ?? 0,
                          });
                        },
                      },
                    ],
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'rooms',
                    element: <ConstructionRoomsAssignmentsTab />,
                    loader: async ({ params }) => {
                      const timetableId = getNumber(params?.timetableId);

                      if (!timetableId) {
                        throw404Error();
                      }

                      return getRoomAssignments({ timetableId });
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'schedule',
                    element: <TimetableConstructionConstructionTabContainer />,
                    children: [
                      {
                        type: NavObjectType.NonMenuLink,
                        index: true,
                        loader: () => redirect('./schedule'),
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'schedule',
                        element: <ConstructionScheduleTab />,
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'overview',
                        element: <ConstructionScheduleOverviewTab />,
                        loader: async ({ params }) => {
                          const timetableId = getNumber(params?.timetableId);

                          if (!timetableId) {
                            throw404Error();
                          }

                          const [{ catalogue_groupTags: groupTags }] =
                            await Promise.all([
                              getCatalogueGroupTags({
                                tagTypes: [
                                  GroupTagType.ProgrammeStage,
                                  GroupTagType.SupportGrouping,
                                  GroupTagType.Meeting,
                                ],
                              }),
                              getTtSolveStatus({ timetableId }),
                            ]);

                          const { ttsolve_scheduleView: scheduleView } =
                            await getScheduleView({
                              timetableId,
                              groupTagIds: groupTags.map(({ id }) => id),
                            });

                          for (const data of scheduleView.byGroupTag) {
                            queryClient.setQueryData(
                              timetableKeys.scheduleView({
                                timetableId,
                                groupTagIds: [data.groupTagId],
                              }),
                              {
                                ttsolve_scheduleView: {
                                  byGroupTag: [data],
                                },
                              },
                            );
                          }

                          return scheduleView;
                        },
                      },
                    ],
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'review',
                    element: <ConstructionReviewTimetable />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'print',
                    element: <TimetableConstructionPrintTabContainer />,
                    hasAccess: ({ isStaffUserWithPermission }) =>
                      isStaffUserWithPermission(
                        'ps:1:printing_and_exporting:print_timetables',
                      ),
                    children: [
                      {
                        type: NavObjectType.NonMenuLink,
                        index: true,
                        loader: () => redirect('./staff'),
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'staff',
                        element: <StaffTimetable />,
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'years',
                        element: <PrintYearGroupTimetable />,
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'class',
                        element: <PrintClassGroupTimetable />,
                      },
                      {
                        type: NavObjectType.NonMenuLink,
                        path: 'room',
                        element: <PrintRoomTimetable />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            title: t('navigation:management.timetable.editTimetable'),
            path: 'edit-timetable',
            element: <EditTimetableContainer />,
            loader: () => getTimetable({ liveTimetable: true }),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./timetable'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'timetable',
                loader: async () => {
                  const [liveTimetable, groupTags] = await Promise.all([
                    getTimetable({ liveTimetable: true }),
                    getCatalogueGroupTags({
                      tagTypes: [GroupTagType.YearGroup],
                    }),
                  ]);

                  const sixthYearGroup = groupTags.catalogue_groupTags.find(
                    ({ refId, type }) =>
                      refId === '6' && type === GroupTagType.YearGroup,
                  );

                  if (liveTimetable && sixthYearGroup) {
                    // @ts-ignore
                    return getTimetableResourceView({
                      timetableId: liveTimetable.timetableId,
                      criteria: {
                        searchType: TtSearchType.Or,
                        timetableGroupIds: [],
                        roomIds: [],
                        groupTags: [sixthYearGroup.id],
                        classGroupIds: [],
                        staffIds: [],
                        // studentIds: [],
                        subjectIds: [],
                      },
                    });
                  }

                  return null;
                },
                element: <Timetable />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'groups',
                loader: async () => {
                  const liveTimetable = await getTimetable({
                    liveTimetable: true,
                  });
                  const { timetableId } = liveTimetable;
                  getTtBlocksList({ timetableId });
                  return liveTimetable
                    ? getTimetableGroups({
                        timetableId,
                        groupTags: [],
                      })
                    : null;
                },
                element: <TimetableSubjectGroups />,
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            title: t('navigation:management.timetable.onCallDuty'),
            path: 'on-call-duty',
            element: <OnCallDutyContainer />,
            loader: () => getTimetable({ liveTimetable: true }),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./on-call'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'on-call',
                element: <OnCall />,
                loader: async () => {
                  const [liveTimetable, { catalogue_groupTags: onCallGroups }] =
                    await Promise.all([
                      getTimetable({
                        liveTimetable: true,
                      }),
                      getCatalogueGroupTags({
                        tagTypes: [GroupTagType.OnCall],
                      }),
                    ]);

                  return Promise.all([
                    getTimetableResourceView({
                      timetableId: liveTimetable.timetableId,
                      criteria: {
                        searchType: TtSearchType.Or,
                        groupTags: onCallGroups?.map(({ id }) => id) ?? [],
                        roomIds: [],
                        timetableGroupIds: [],
                        classGroupIds: [],
                        staffIds: [],
                        studentIds: [],
                        subjectIds: [],
                      },
                    }),
                    getSearchFormsSubmissions({
                      links: [
                        {
                          id: String(liveTimetable.timetableId ?? ''),
                          type: Forms_LinkEntityType.Timetable,
                        },
                      ],
                    }),
                  ]);
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'duty',
                element: <Duty />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'groups',
                element: <OnCallDutyGroups />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'assignments',
                element: <OnCallDutyAssignments />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'forms',
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission('ps:1:forms:forms_users'),
                loader: async () => {
                  const liveTimetable = await getTimetable({
                    liveTimetable: true,
                  });
                  const { timetableId } = liveTimetable;

                  return getSearchFormsSubmissions({
                    links: [
                      {
                        id: String(timetableId ?? ''),
                        type: Forms_LinkEntityType.Timetable,
                      },
                    ],
                  });
                },
                element: <OnCallForms />,
              },
            ],
          },
        ],
      },
    ],
  },
];
