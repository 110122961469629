import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type CreateSubjectInput,
  type SubjectFilter,
  type UpdateSubject,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';

const catalogueSubjects = graphql(/* GraphQL */ `
  query catalogueSubjects($filter: SubjectFilter) {
    catalogue_subjects(filter: $filter) {
      id
      name
      description
      shortCode
      nationalCode
      subjectSource
      examinable
      colour
      icon
      active
    }
  }
`);

const createCatalogueSubjects = graphql(/* GraphQl */ `
  mutation catalogue_createSubject($input: CreateSubjectInput) {
    catalogue_createSubject(input: $input) {
      id
      name
      shortCode
      subjectSource
      active
      inUseHistorically
      examinable
    }
  }
`);

const updateCatalogueSubjects = graphql(/* GraphQL */ `
  mutation catalogue_updateSubjects($input: [UpdateSubject!]!) {
    catalogue_updateSubjects(input: $input) {
      success
      message
    }
  }
`);

export const catalogueSubjectsKeys = {
  all: ['catalogue_subjects'] as const,
  subjects: (filter?: SubjectFilter) =>
    [...catalogueSubjectsKeys.all, 'subjects', filter] as const,
  createLocalSubject: () =>
    [...catalogueSubjectsKeys.all, 'createLocalSubject'] as const,
  updateLocalSubject: () =>
    [...catalogueSubjectsKeys.all, 'updateLocalSubject'] as const,
};

const catalogueSubjectsQuery = (filter?: SubjectFilter) => ({
  queryKey: catalogueSubjectsKeys.subjects(filter),
  queryFn: async () => gqlClient.request(catalogueSubjects, { filter }),
});

export function getCatalogueSubjects(filter?: SubjectFilter) {
  return queryClient.fetchQuery(catalogueSubjectsQuery(filter));
}

export function useCatalogueSubjects(filter?: SubjectFilter) {
  return useQuery({
    ...catalogueSubjectsQuery(filter),
    select: ({ catalogue_subjects }) =>
      catalogue_subjects.sort(
        (a, b) => Number(a.nationalCode) - Number(b.nationalCode),
      ),
  });
}

export function useUpdateCatalogueSubjects() {
  const { toast } = useToast();
  const { t } = useTranslation(['settings']);

  return useMutation({
    mutationKey: catalogueSubjectsKeys.updateLocalSubject(),
    mutationFn: (input: UpdateSubject[]) =>
      gqlClient.request(updateCatalogueSubjects, { input }),
    onSuccess: () => {
      toast(t('settings:successfullyUpdatedSubjects'), {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: catalogueSubjectsKeys.all });
    },
    onError: () => {
      toast(t('settings:updateToSubjectsUnsuccessful'), {
        variant: 'error',
      });
    },
  });
}

export function useCreateLocalSubject() {
  const { toast } = useToast();
  const { t } = useTranslation(['settings']);

  return useMutation({
    mutationKey: catalogueSubjectsKeys.createLocalSubject(),
    mutationFn: (input: CreateSubjectInput) =>
      gqlClient.request(createCatalogueSubjects, { input }),
    onSuccess: () => {
      toast(t('settings:successfullyCreatedSubject'), {
        variant: 'success',
      });
      queryClient.invalidateQueries({ queryKey: catalogueSubjectsKeys.all });
    },
    onError: () => {
      toast(t('settings:createSubjectUnsuccessful'), {
        variant: 'error',
      });
    },
  });
}

export type CatalogueSubjectOption = UseQueryReturnType<
  typeof useCatalogueSubjects
>[number];
