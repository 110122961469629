import { useQuery } from '@tanstack/react-query';
import {
  type Core_CustomFieldFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { customFieldsKeys } from './keys';

const customFields = graphql(/* GraphQL */ `
  query customFields($filter: Core_CustomFieldFilter) {
    core_customFields(filter: $filter) {
      id
      name
      type
      options
      source
    }
  }
`);

const customFieldsQuery = (filter: Core_CustomFieldFilter) => ({
  queryKey: customFieldsKeys.customFields(filter),
  queryFn: async () => gqlClient.request(customFields, { filter }),
});

export function getCustomFields(filter: Core_CustomFieldFilter) {
  return queryClient.fetchQuery(customFieldsQuery(filter));
}

export function useCustomFields(filter: Core_CustomFieldFilter) {
  return useQuery({
    ...customFieldsQuery(filter),
    select: ({ core_customFields }) => core_customFields,
  });
}

export type ReturnTypeFromUseCustomFields = UseQueryReturnType<
  typeof useCustomFields
>[number];
