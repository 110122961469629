import { AttendanceCodeType } from '@tyro/api';
import {
  type NavObjectFunction,
  NavObjectType,
  lazyWithRetry,
} from '@tyro/core';
import { PersonCheckmarkIcon } from '@tyro/icons';
import dayjs from 'dayjs';
import { getAbsentRequests, getAttendanceCodes } from './api';
import { getBulkAttendance } from './api/bulk-attendance/bulk-attendance';
import { getSessionAttendanceList } from './api/session-attendance-table';
import { PendingAbsentRequestCountLabel } from './components/absent-requests/pending-absent-request-count-label';

const SessionAttendance = lazyWithRetry(() => import('./pages/session'));
const SessionAttendanceList = lazyWithRetry(
  () => import('./pages/session-attendance-list'),
);
const AbsentRequests = lazyWithRetry(() => import('./pages/absent-requests'));
const BulkAttendance = lazyWithRetry(() => import('./pages/bulk-attendance'));
const SignInOutPage = lazyWithRetry(() => import('./pages/sign-in-out'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'attendance',
        title: t('navigation:general.attendance.title'),
        icon: <PersonCheckmarkIcon />,
        hasAccess: (permissions) => !permissions.isTyroTenantAndUser,
        children: [
          {
            type: NavObjectType.MenuLink,
            path: 'session',
            title: t('navigation:general.attendance.session'),
            element: <SessionAttendance />,
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission(
                'ps:1:attendance:read_session_attendance',
              ),
          },
          {
            type: NavObjectType.MenuLink,
            path: 'session-list',
            title: t('navigation:general.attendance.unexplainedAbsences'),
            element: <SessionAttendanceList />,
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission(
                'ps:1:attendance:read_session_attendance',
              ),
            loader: async () => {
              const { attendance_attendanceCodes: activeAttendanceCodes } =
                await getAttendanceCodes({ active: true });
              const unexplainedCodes = activeAttendanceCodes.filter(
                (code) =>
                  code.sessionCodeType ===
                  AttendanceCodeType.UnexplainedAbsence,
              );

              return getSessionAttendanceList({
                attendanceCodeIds: unexplainedCodes.map(({ id }) => id),
                from: dayjs().startOf('day').format('YYYY-MM-DD'),
                to: dayjs().endOf('day').format('YYYY-MM-DD'),
              });
            },
          },
          {
            type: NavObjectType.MenuLink,
            path: 'absent-requests',
            info: <PendingAbsentRequestCountLabel />,
            title: t('navigation:general.attendance.absentRequests'),
            loader: () => getAbsentRequests({}),
            hasAccess: ({ hasPermission }) =>
              hasPermission(
                'ps:1:attendance:read_parental_attendance_requests',
              ),
            element: <AbsentRequests />,
          },
          {
            type: NavObjectType.MenuLink,
            path: 'bulk-attendance',
            title: t('navigation:general.attendance.bulkAttendance'),
            element: <BulkAttendance />,
            loader: () => getBulkAttendance({}),
            hasAccess: ({ hasPermission }) =>
              hasPermission('ps:1:attendance:write_bulk_attendance'),
          },
          {
            type: NavObjectType.MenuLink,
            path: 'sign-in-out',
            title: t('navigation:general.attendance.signInOut'),
            element: <SignInOutPage />,
            hasAccess: ({ hasPermission }) =>
              hasPermission('ps:1:attendance:read_sign_in_out'),
          },
        ],
      },
    ],
  },
];
