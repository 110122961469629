import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type ClassGroupsListQuery,
  EmulateHeaders,
  GeneralGroupType,
  type QueryAdditionalOptions,
  type UpdateClassGroupGroupInput,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useCallback } from 'react';
import { groupsKeys } from './keys';

const classGroupsList = graphql(/* GraphQL */ `
  query classGroupsList($filter: GeneralGroupFilter!) {
    generalGroups(filter: $filter) {
      partyId
      name
      avatarUrl
      studentMembers {
        memberCount
      }
      generalGroupType
      programmeStages {
        programme {
          name
        }
      }
      tutors {
        partyId
        title {
          id
          name
          nameTextId
        }
        firstName
        lastName
        type
      }
      students {
        person {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
      }
      yearGroupLeads {
        partyId
        title {
          id
          name
          nameTextId
        }
        firstName
        lastName
        type
      }
      yearGroups {
        yearGroupId
        name
      }
    }
  }
`);

const classGroupById = graphql(/* GraphQL */ `
  query classGroupsById($filter: GeneralGroupFilter!) {
    generalGroups(filter: $filter) {
      partyId
      name
      students {
        person {
          partyId
          title {
            nameTextId
            id
            name
          }
          firstName
          lastName
          avatarUrl
          type
        }
        extensions {
          priority
          aen
        }
      }
      relatedSubjectGroups {
        name
        partyId
        avatarUrl
        studentMembershipType {
          type
        }
        subjects {
          name
          colour
        }
        programmeStages {
          name
        }
        staff {
          title {
            id
            nameTextId
            name
          }
          type
          firstName
          lastName
        }
        irePP {
          level
        }
        studentMembers {
          memberCount
        }
      }
      blocks {
        blockId
        name
      }
    }
  }
`);

const updateClassGroups = graphql(/* GraphQL */ `
  mutation core_updateClassGroups($input: [UpdateClassGroupGroupInput!]) {
    core_updateClassGroups(input: $input) {
      success
    }
  }
`);

const classGroupsQuery = (
  academicNamespaceId: QueryAdditionalOptions['academicNamespaceId'],
) => ({
  queryKey: groupsKeys.class.groups(academicNamespaceId),
  queryFn: async () =>
    gqlClient.request(
      classGroupsList,
      {
        filter: {
          groupTypes: [GeneralGroupType.ClassGroup],
        },
      },
      academicNamespaceId
        ? {
            [EmulateHeaders.ACADEMIC_NAMESPACE_ID]:
              academicNamespaceId.toString(),
          }
        : {},
    ),
});

export function getClassGroups(
  academicNamespaceId?: QueryAdditionalOptions['academicNamespaceId'],
) {
  return queryClient.fetchQuery(classGroupsQuery(academicNamespaceId));
}

export function useClassGroups(
  {
    enabled = true,
    academicNamespaceId,
  }: QueryAdditionalOptions | undefined = {
    enabled: true,
    academicNamespaceId: undefined,
  },
) {
  return useQuery({
    ...classGroupsQuery(academicNamespaceId),
    enabled,
    select: useCallback(
      ({ generalGroups }: ClassGroupsListQuery) => generalGroups,
      [],
    ),
  });
}

const classGroupsByIdQuery = (id: number | undefined) => ({
  queryKey: groupsKeys.class.details(id),
  queryFn: async () =>
    gqlClient.request(classGroupById, {
      filter: {
        partyIds: [id ?? 0],
      },
    }),
});

export function getClassGroupsById(id: number | undefined) {
  return queryClient.fetchQuery(classGroupsByIdQuery(id));
}

export function useClassGroupById(id: number | undefined) {
  return useQuery({
    ...classGroupsByIdQuery(id),
    select: ({ generalGroups }) => {
      if (!generalGroups) return null;
      const group = generalGroups[0];
      return group;
    },
  });
}

export function useSaveClassGroupEdits() {
  return useMutation({
    mutationFn: (input: UpdateClassGroupGroupInput[]) =>
      gqlClient.request(updateClassGroups, { input }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: groupsKeys.class.all() });
    },
  });
}

export type ReturnTypeFromUseClassGroups = UseQueryReturnType<
  typeof useClassGroups
>[number];

export type ReturnTypeFromUseClassGroupById = UseQueryReturnType<
  typeof useClassGroupById
>;
