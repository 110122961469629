import {
  Badge,
  Box,
  Button,
  Drawer,
  Fade,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import { NotificationType, queryClient, useUser } from '@tyro/api';
import {
  Avatar,
  Scrollbar,
  displayName,
  useDisclosure,
  useRelativeDateFormat,
  useToast,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  BellIcon,
  CalendarAddIcon,
  DocEditIcon,
  DoubleCheckmarkIcon,
  GraduateHatLoadingIcon,
  HouseSpeechHeartIcon,
  MailPostLetterIcon,
  SchoolExamACircleIcon,
  UserIcon,
  WalletWithMoneyIcon,
  XHalfDashCircleIcon,
} from '@tyro/icons';
import { mailKeys } from '@tyro/mail';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { shellKeys } from '../../../api/keys';
import {
  type ReturnTypeFromNotifications,
  getNotifications,
  useNotifications,
} from '../../../api/notifications/list';
import { useMarkNotificationRead } from '../../../api/notifications/read-notification';
import { useNotificationsUnreadCount } from '../../../api/notifications/unread-count';
import { useSwitchProfile } from '../../../api/switch-profile';
import { useNotificationLinks } from '../../../hooks/use-notification-links';
import { IconButtonAnimate } from '../../icon-button-animate';

dayjs.extend(relativeTime);

const NotificationIconMap = {
  [NotificationType.Absence]: {
    bgColor: 'pink.100',
    color: 'pink.500',
    Icon: XHalfDashCircleIcon,
  },
  [NotificationType.Assessment]: {
    bgColor: 'emerald.100',
    color: 'emerald.500',
    Icon: SchoolExamACircleIcon,
  },
  [NotificationType.Fee]: {
    bgColor: 'orange.100',
    color: 'orange.500',
    Icon: WalletWithMoneyIcon,
  },
  [NotificationType.Individual]: {
    bgColor: 'yellow.100',
    color: 'yellow.500',
    Icon: UserIcon,
  },
  [NotificationType.Mail]: {
    bgColor: 'blue.100',
    color: 'blue.600',
    Icon: MailPostLetterIcon,
  },
  [NotificationType.Substitution]: {
    bgColor: 'green.100',
    color: 'green.500',
    Icon: GraduateHatLoadingIcon,
  },
  [NotificationType.Timetable]: {
    bgColor: 'purple.100',
    color: 'purple.500',
    Icon: CalendarAddIcon,
  },
  [NotificationType.Wellbeing]: {
    bgColor: 'rose.100',
    color: 'rose.600',
    Icon: HouseSpeechHeartIcon,
  },
  [NotificationType.InfoRequest]: {
    bgColor: 'purple.100',
    color: 'purple.500',
    Icon: DocEditIcon,
  },
} as const;

export function NotificationsPopover() {
  const latestNotificationRef = useRef<
    ReturnTypeFromNotifications | 'empty' | null
  >(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, activeProfile } = useUser();
  const { t } = useTranslation(['common', 'calendar']);
  const { getRelativeDateFormat } = useRelativeDateFormat();
  const { toast, closeAllToasts } = useToast();

  const { data: unreadCount } = useNotificationsUnreadCount();
  const { data: notifications } = useNotifications({
    globalUserId: user?.id ?? 0,
  });
  const { mutateAsync: markNotificationRead } = useMarkNotificationRead();
  const { mutateAsync: switchProfile } = useSwitchProfile();
  const notificationUrls = useNotificationLinks(notifications);

  const showMarkAllAsRead = useMemo(
    () =>
      notifications?.some(
        (notification) => notification.recipients?.[0].readOn === undefined,
      ) ?? false,
    [notifications],
  );
  const groupedNotifications = useMemo(() => {
    if (!notifications) return [];

    const groupedData = notifications.reduce((acc, notification) => {
      const sentOn = dayjs(notification.sentOn);
      const notificationDate = sentOn.calendar(null, {
        sameDay: `[${t('calendar:today')}]`,
        lastDay: `[${t('calendar:yesterday')}]`,
        lastWeek: `[${t('calendar:lastWeek')}]`,
        sameElse: 'LL',
      });

      const notificationsForDate = acc.get(notificationDate) ?? [];
      notificationsForDate.push(notification);
      acc.set(notificationDate, notificationsForDate);

      return acc;
    }, new Map<string, ReturnTypeFromNotifications[]>());

    const sortedKeys = [...groupedData.keys()].sort((keyA, keyB) => {
      const [firstDataA] = groupedData.get(keyA) || [];
      const [firstDataB] = groupedData.get(keyB) || [];

      return dayjs(firstDataB.sentOn).unix() - dayjs(firstDataA.sentOn).unix();
    });

    return sortedKeys.flatMap((date) => {
      const notificationsForDate = groupedData.get(date) ?? [];

      return [
        date,
        ...notificationsForDate.sort(
          (notificationA, notificationB) =>
            dayjs(notificationB.sentOn).unix() -
            dayjs(notificationA.sentOn).unix(),
        ),
      ];
    });
  }, [notifications, t]);

  const handleMarkAllAsRead = async () => {
    try {
      const promiseRequest = notifications
        ?.filter(({ recipients }) => recipients?.[0].readOn === undefined)
        .map((notification) =>
          markNotificationRead({ notificationId: notification.id }),
        );
      if (!promiseRequest || promiseRequest.length === 0) return;

      await Promise.all(promiseRequest);
      queryClient.invalidateQueries({
        queryKey: shellKeys.notifications.all(),
      });
    } catch (error) {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    }
  };

  const checkLatestNotification = async () => {
    queryClient.invalidateQueries({
      queryKey: shellKeys.notifications.list({
        globalUserId: user?.id ?? 0,
      }),
    });
    const { communications_notifications: latestNotifications } =
      await getNotifications({
        globalUserId: user?.id ?? 0,
      });
    const sortedLatestNotifications = latestNotifications.sort(
      (a, b) => dayjs(b.sentOn).unix() - dayjs(a.sentOn).unix(),
    );

    let count = 0;
    const [firstNotification] = sortedLatestNotifications;
    const latestRef = latestNotificationRef.current;

    if (latestRef !== 'empty' && firstNotification?.id === latestRef?.id)
      return;

    if (latestRef === 'empty') {
      count = sortedLatestNotifications.reduce(
        (acc, notification) =>
          notification.recipients?.[0].readOn === undefined ? acc + 1 : acc,
        0,
      );
    } else if (latestRef !== null) {
      count = sortedLatestNotifications.reduce(
        (acc, notification) =>
          notification.recipients?.[0].readOn === undefined &&
          dayjs(notification.sentOn).isAfter(dayjs(latestRef.sentOn))
            ? acc + 1
            : acc,
        0,
      );
    }

    if (count > 0) {
      toast(t('xNewNotifications', { count }), {
        action: (
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              closeAllToasts();
              onOpen();
            }}
          >
            {t('common:actions.view')}
          </Button>
        ),
      });
    }
    latestNotificationRef.current = firstNotification;
  };

  const onNotificationClick = async (
    e: React.MouseEvent,
    notification: ReturnTypeFromNotifications,
  ) => {
    const recipient = notification.recipients?.[0];
    const isUnread = !recipient?.readOn;
    const isActiveProfile =
      activeProfile?.partyId === recipient?.recipientPartyId;

    if (isUnread) {
      await markNotificationRead(
        { notificationId: notification.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: shellKeys.notifications.all(),
            });
            if (notification.notificationType === NotificationType.Mail) {
              queryClient.invalidateQueries({
                queryKey: mailKeys.unreadCounts(),
              });
              queryClient.invalidateQueries({ queryKey: mailKeys.lists() });
            }
          },
        },
      );
    }

    if (!isActiveProfile) {
      e.preventDefault();
      const notificationUrl = notificationUrls.get(notification.id);
      const requestedProfile = user?.profiles?.find(
        (profile) => profile.partyId === recipient?.recipientPartyId,
      );

      if (requestedProfile && notificationUrl) {
        e.preventDefault();
        await switchProfile(
          {
            globalUserId: user!.id!,
            requestedProfileId: requestedProfile.id,
            partyId: requestedProfile.partyId!,
            tenant: requestedProfile.tenantId!,
          },
          {
            onSuccess: () => {
              window.location.href = notificationUrl;
            },
            onError: () => {
              toast(t('common:failedToSwitchProfile'), { variant: 'error' });
            },
          },
        );
      }
    }

    onClose();
  };

  useEffect(() => {
    if (unreadCount === 0 || latestNotificationRef.current === null) return;

    checkLatestNotification();
  }, [unreadCount]);

  useEffect(() => {
    if (latestNotificationRef.current === null && notifications !== undefined) {
      const sortedNotifications = notifications.sort(
        (a, b) => dayjs(b.sentOn).unix() - dayjs(a.sentOn).unix(),
      );
      latestNotificationRef.current = sortedNotifications?.[0] ?? 'empty';
    }
  }, [notifications]);

  return (
    <>
      <IconButtonAnimate
        color={isOpen ? 'primary' : 'default'}
        onClick={onOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge
          badgeContent={unreadCount}
          max={99}
          color="error"
          sx={{
            '& .MuiBadge-badge': { minWidth: 16, height: 16, padding: '0 4px' },
          }}
          overlap="circular"
        >
          <BellIcon />
        </Badge>
      </IconButtonAnimate>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        ModalProps={{
          sx: {
            '& .MuiBackdrop-root': {
              opacity: '0 !important',
            },
          },
        }}
        PaperProps={{
          sx: ({ palette }) => ({
            width: '100%',
            maxWidth: 320,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(20px)',
            borderRadius: '24px 0 0 24px',
            border: '1px solid',
            borderRight: 'none',
            borderColor: 'indigo.50',
            boxShadow: `0px 4px 74px ${alpha(
              palette.indigo[300],
              0.3,
            )} !important`,
          }),
        }}
      >
        <Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              px: 2,
              py: 1,
              borderBottom: 'solid 1px',
              borderBottomColor: 'divider',
              backgroundColor: 'background.paper',
              position: 'sticky',
              top: 0,
              zIndex: 2,
            }}
          >
            <Typography component="h2" variant="h6">
              {t('common:notifications')}
            </Typography>
            <Fade in={showMarkAllAsRead}>
              <Tooltip title={t('common:markAllAsRead')}>
                <IconButton
                  size="small"
                  color="success"
                  onClick={handleMarkAllAsRead}
                >
                  <DoubleCheckmarkIcon />
                </IconButton>
              </Tooltip>
            </Fade>
          </Stack>

          <Scrollbar>
            <List
              disablePadding
              sx={{
                backgroundColor: 'transparent',
                '& .MuiListSubheader-root': {
                  color: 'text.primary',
                  fontWeight: 'bold',
                  backgroundColor: 'transparent',
                  py: 1,
                  lineHeight: 1.5,
                },
                '& .MuiListItemButton-root': {
                  border: 'solid 1px',
                  borderColor: 'divider',
                  p: 1.25,
                  m: 1,
                  borderRadius: 2,
                },
              }}
            >
              {groupedNotifications?.map((notification) => {
                if (typeof notification === 'string') {
                  return (
                    <ListSubheader key={notification}>
                      {notification}
                    </ListSubheader>
                  );
                }

                const { id, title, text, sender, notificationType } =
                  notification;
                const notificationIcon = NotificationIconMap[notificationType];
                const isUnread = !notification.recipients?.[0].readOn;
                const link = notificationUrls.get(id);
                const hasLink = !!link;

                return (
                  <ListItemButton
                    component={hasLink ? Link : 'div'}
                    to={link}
                    key={id}
                    sx={({ palette }) => ({
                      backgroundColor: isUnread
                        ? palette.action.hover
                        : 'transparent',
                      '&:hover': {
                        backgroundColor: palette.action.selected,
                      },
                    })}
                    onClick={(e: React.MouseEvent) =>
                      onNotificationClick(e, notification)
                    }
                  >
                    {Object.keys(sender).length > 0 && (
                      <ListItemAvatar>
                        <Avatar
                          name={displayName(sender)}
                          person={sender}
                          src={sender.avatarUrl}
                        />
                      </ListItemAvatar>
                    )}
                    <ListItemText
                      primary={title}
                      secondary={
                        <Stack component="span">
                          <span>{text}</span>
                          <Typography variant="caption" color="text.secondary">
                            {getRelativeDateFormat(notification.sentOn)}
                          </Typography>
                        </Stack>
                      }
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 36,
                        minWidth: 36,
                        height: 36,
                        borderRadius: '50%',
                        backgroundColor: notificationIcon.bgColor,
                        color: notificationIcon.color,

                        '& svg': {
                          width: 20,
                          height: 20,
                        },
                      }}
                    >
                      <notificationIcon.Icon />
                    </Box>
                  </ListItemButton>
                );
              })}
              {groupedNotifications?.length === 0 && (
                <Stack
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    py: 4,
                  }}
                >
                  <Typography variant="h2" component="span">
                    🔔
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    color="text.secondary"
                  >
                    {t('common:youreAllCaughtUp')}
                  </Typography>
                </Stack>
              )}
            </List>
          </Scrollbar>
        </Stack>
      </Drawer>
    </>
  );
}
