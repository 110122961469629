import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import {
  FormAdmin_InformationRequestConfigMetaType,
  type FormsAdmin_InformationRequestDefinitionIdInput,
  type Timetable_OnCallAssignerOptions,
} from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFDateTimePicker,
  RHFTextField,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  RHFStaffAutocomplete,
  type StaffSelectOption,
  getStaffForSelect,
} from '@tyro/people';
import type { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUpsertInformationRequestUsingMetaConfig } from '../../api/upsert-information-request';

type FormState = {
  id?: FormsAdmin_InformationRequestDefinitionIdInput;
  timetableId: number;
  isInUse: boolean;
  periodsToChooseFrom: number;
  maxPeriodsTeacherCanGet: number;
  requiredTeachersPerPeriod: number;
  dueDate: Dayjs;
  publishDate: Dayjs;
  excludeTeachers?: StaffSelectOption[];
  excludeTeachersIds?: StaffSelectOption['partyId'][];
};

export type UpsertOnCallFormModalProps = {
  timetableId: number;
  initialState?: Partial<FormState> | null;
  isOpen: boolean;
  onClose: () => void;
};

export function UpsertOnCallFormModal({
  timetableId,
  initialState,
  isOpen,
  onClose,
}: UpsertOnCallFormModalProps) {
  const { t } = useTranslation(['common', 'forms']);

  const { mutate: upsertInformationRequestUsingMetaConfig, isPending } =
    useUpsertInformationRequestUsingMetaConfig();

  const { resolver, rules } = useFormValidator<FormState>();

  const { control, handleSubmit, reset } = useForm<FormState>({
    resolver: resolver({
      periodsToChooseFrom: [rules.required(), rules.isNumber()],
      maxPeriodsTeacherCanGet: [rules.required(), rules.isNumber()],
      requiredTeachersPerPeriod: [rules.required(), rules.isNumber()],
      dueDate: [rules.required(), rules.date()],
      publishDate: [rules.required(), rules.date()],
    }),
    defaultValues: initialState || {},
  });

  useEffect(() => {
    if (isOpen) {
      const restState = async () => {
        reset({ ...initialState });

        let excludeTeachers: StaffSelectOption[] = [];
        if (initialState?.excludeTeachersIds?.length) {
          const { core_staff: teachersData } = await getStaffForSelect({
            partyIds: initialState?.excludeTeachersIds || [],
          });
          excludeTeachers = teachersData;
        }

        reset({ ...initialState, excludeTeachers });
      };

      restState();
    }
  }, [isOpen, initialState]);

  const onSubmit = handleSubmit(
    ({
      id,
      periodsToChooseFrom,
      maxPeriodsTeacherCanGet,
      requiredTeachersPerPeriod,
      dueDate,
      publishDate,
      excludeTeachers,
    }) => {
      const data: Timetable_OnCallAssignerOptions = {
        timetableId,
        type: FormAdmin_InformationRequestConfigMetaType.TtOnCallRequest,
        __typename: 'Timetable_OnCallAssignerOptions',
        periodsToChooseFrom: Number(periodsToChooseFrom),
        maxPeriodsTeacherCanGet: Number(maxPeriodsTeacherCanGet),
        requiredTeachersPerPeriod: Number(requiredTeachersPerPeriod),
        dueDate: dueDate.format('YYYY-MM-DDTHH:mm:ss'),
        publishDate: publishDate.format('YYYY-MM-DDTHH:mm:ss'),
        excludeTeachers: excludeTeachers?.map((teacher) => teacher.partyId),
      };
      upsertInformationRequestUsingMetaConfig(
        { id, config: data },
        { onSuccess: onClose },
      );
    },
  );

  const isInUse = initialState?.isInUse;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle onClose={onClose}>
          {initialState?.dueDate
            ? t('forms:editOnCallForm')
            : t('forms:addOnCallForm')}
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} mt={1}>
            <RHFTextField
              label={t('forms:maxPeriodsTeachersCanSubmit')}
              textFieldProps={{
                type: 'number',
                disabled: isInUse,
              }}
              controlProps={{
                name: 'periodsToChooseFrom',
                control,
              }}
            />

            <RHFTextField
              label={t('forms:maxPeriodsTeacherCanGet')}
              textFieldProps={{
                type: 'number',
                disabled: isInUse,
              }}
              controlProps={{
                name: 'maxPeriodsTeacherCanGet',
                control,
              }}
            />

            <RHFTextField
              label={t('forms:requiredTeachersPerPeriod')}
              textFieldProps={{
                type: 'number',
                disabled: isInUse,
              }}
              controlProps={{
                name: 'requiredTeachersPerPeriod',
                control,
              }}
            />

            <RHFDateTimePicker
              label={t('common:publishDate')}
              controlProps={{
                control,
                name: 'publishDate',
              }}
              inputProps={{
                fullWidth: true,
              }}
            />

            <RHFDateTimePicker
              label={t('common:dueDate')}
              inputProps={{
                fullWidth: true,
              }}
              controlProps={{
                control,
                name: 'dueDate',
              }}
            />

            <RHFStaffAutocomplete
              label={t('forms:excludeTeachers')}
              multiple
              disabled={isInUse}
              controlProps={{
                name: 'excludeTeachers',
                control,
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            disabled={isPending}
            onClick={onClose}
          >
            {t('common:actions.cancel')}
          </Button>

          <LoadingButton loading={isPending} type="submit" variant="contained">
            {t('common:actions.confirm')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
