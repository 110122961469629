import {
  ConfirmDialog,
  IconButtonWithTooltip,
  RHFRadioGroup,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';

import { Calender_EventDeleteMode } from '@tyro/api';

import { TrashIcon } from '@tyro/icons';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDeleteCalendarEvents } from '../../../api/delete-events';
import type { ReturnTypeFromUseEventForEdit } from '../../../api/events-for-edit';

export interface DeleteCalendarEventButtonProps {
  eventForEdit: ReturnTypeFromUseEventForEdit;
  deleteFromDate: string;
  onClose: () => void;
}

const modeOptions = [
  Calender_EventDeleteMode.DeleteHistoric,
  Calender_EventDeleteMode.DeleteFromDate,
] as const;

type FormState = {
  mode: (typeof modeOptions)[number];
};

export function DeleteCalendarEventButton({
  eventForEdit,
  deleteFromDate,
  onClose,
}: DeleteCalendarEventButtonProps) {
  const { t } = useTranslation(['common', 'calendar']);
  const [isOpenRecurringEventModal, setIsOpenRecurringEventModal] =
    useState(false);

  const { mutateAsync: deleteEvent, isPending: isDeleting } =
    useDeleteCalendarEvents();

  const { control, handleSubmit } = useForm<FormState>({
    defaultValues: {
      mode: Calender_EventDeleteMode.DeleteHistoric,
    },
  });

  const handleClose = () => {
    setIsOpenRecurringEventModal(false);
    onClose();
  };

  const onSubmit = handleSubmit(async ({ mode }) => {
    await deleteEvent({
      mode,
      deleteByEventId: [eventForEdit.eventId],
      deleteBySource: [],
      ...(mode === Calender_EventDeleteMode.DeleteFromDate && {
        deleteFromDate,
      }),
    });
    handleClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <ConfirmDialog
        open={isOpenRecurringEventModal}
        onClose={handleClose}
        onConfirm={onSubmit}
        title={t('calendar:deleteRecurringEvent')}
        content={
          <RHFRadioGroup
            options={modeOptions.map((option) => ({
              value: option,
              label: t(`calendar:deleteEventMode.${option}`),
            }))}
            controlProps={{
              name: 'mode',
              control,
            }}
          />
        }
        confirmText={t('common:actions.delete')}
        isDelete
      />

      <IconButtonWithTooltip
        disabled={isDeleting}
        title={t('common:actions.delete')}
        aria-label={t('common:actions.delete')}
        size="small"
        type="submit"
        {...(!!eventForEdit?.schedule?.recurrenceRule && {
          type: 'button',
          onClick: () => {
            setIsOpenRecurringEventModal(true);
          },
        })}
      >
        <TrashIcon
          sx={{
            width: 16,
            height: 16,
          }}
        />
      </IconButtonWithTooltip>
    </form>
  );
}
