import {
  Box,
  Card,
  Collapse,
  Fade,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import type { Person } from '@tyro/api';
import {
  ActionMenu,
  LinkRender,
  LoadingPlaceholderContainer,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { EyeIcon, FullScreenIcon } from '@tyro/icons';
import { StudentAvatar } from '@tyro/people';
import {
  Report,
  getReportUrl,
  useReportFormatValues,
  useRunReports,
} from '@tyro/reporting';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import type { ReportChipValue } from '../types';

enum PriorityStudentsGroupByFilter {
  MY_GROUPS = 'MY_GROUPS',
  ALL = 'ALL',
}

const toDate = dayjs();
const fromDate = dayjs();

export function PriorityStudentsWidget() {
  const { t } = useTranslation(['common', 'reports']);

  const [groupFilter, setGroupFilter] = useState<PriorityStudentsGroupByFilter>(
    PriorityStudentsGroupByFilter.MY_GROUPS,
  );

  const onlyMyGroups = groupFilter === PriorityStudentsGroupByFilter.MY_GROUPS;

  const { data: priorityData, isLoading } = useRunReports({
    topReportId: Report.STUDENT_PRIORITY_NOTES,
    filter: {
      reportId: Report.STUDENT_PRIORITY_NOTES,
      limit: 5,
      filters: [
        {
          filterId: 'from_date',
          filterValue: fromDate.format('YYYY-MM-DD'),
        },
        {
          filterId: 'to_date',
          filterValue: toDate.format('YYYY-MM-DD'),
        },
        {
          filterId: 'only_my_groups',
          filterValue: onlyMyGroups,
        },
      ],
    },
  });

  const { mapField } = useReportFormatValues({});

  const priorityStudents = mapField<{
    id: number;
    avatar_cell: Person & { aenFlag: boolean };
    party_id: number;
    class: string;
    tags: ReportChipValue[];
    date: string;
    details: string;
  }>(priorityData);

  const [showText, setShowText] = useState<Record<number, boolean>>({});

  return (
    <Card
      variant="soft"
      sx={{
        flex: 1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pl={1}
        mb={1}
      >
        <Typography variant="h6" component="span">
          {t('reports:priorityStudents')}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <ActionMenu
            buttonLabel={t(
              `reports:priorityStudentsGroupByFilter.${groupFilter}`,
            )}
            buttonProps={{
              size: 'small',
            }}
            menuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            }}
            menuItems={[
              PriorityStudentsGroupByFilter.MY_GROUPS,
              PriorityStudentsGroupByFilter.ALL,
            ].map((filter) => ({
              label: t(`reports:priorityStudentsGroupByFilter.${filter}`),
              onClick: () => setGroupFilter(filter),
            }))}
          />
          <IconButton
            component={Link}
            to={getReportUrl({
              report: Report.STUDENT_PRIORITY_NOTES,
              filters: {
                from_date: fromDate,
                to_date: toDate,
                only_my_groups: onlyMyGroups,
              },
            })}
          >
            <FullScreenIcon
              sx={{ width: 20, height: 20, color: 'primary.main' }}
            />
          </IconButton>
        </Stack>
      </Stack>
      {isLoading || priorityStudents.length === 0 ? (
        <Card
          sx={{
            minHeight: 160,
          }}
        >
          <LoadingPlaceholderContainer isLoading={isLoading}>
            <Stack
              sx={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h2" component="span">
                👍
              </Typography>
              <Typography
                variant="body1"
                component="span"
                color="text.secondary"
              >
                {t('reports:noPriorityStudentsToday')}
              </Typography>
            </Stack>
          </LoadingPlaceholderContainer>
        </Card>
      ) : (
        <Stack spacing={1.25}>
          {priorityStudents.map(
            ({
              id,
              avatar_cell: studentPerson,
              party_id: partyId,
              class: classGroup,
              tags,
              details,
            }) => {
              const name = studentPerson?.textValue ?? '';
              const studentPartyId = partyId?.typedValue ?? 0;
              const isShown = showText[studentPartyId];
              const avatarUrl = studentPerson?.typedValue.avatarUrl;
              const hasSupportPlan = !!studentPerson?.typedValue.aenFlag;

              return (
                <Card
                  key={id?.textValue ?? ''}
                  component={Link}
                  to={`/people/students/${studentPartyId}/notes`}
                  sx={{
                    p: 1.5,
                    textDecoration: 'inherit',
                    '&:hover': {
                      bgcolor: 'indigo.100',
                    },
                    '&:active': {
                      bgcolor: 'indigo.200',
                    },
                  }}
                >
                  <Stack spacing={1.5}>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      spacing={1.5}
                    >
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        spacing={1.5}
                        flex={1}
                      >
                        <Box onClick={(e) => e.preventDefault()}>
                          <StudentAvatar
                            partyId={partyId?.typedValue ?? 0}
                            name={name}
                            src={avatarUrl}
                            isPriorityStudent
                            hasSupportPlan={hasSupportPlan}
                            size={48}
                          />
                        </Box>

                        <Collapse
                          in={isShown}
                          collapsedSize={48}
                          sx={{ width: '100%', position: 'relative' }}
                        >
                          <Fade in={!isShown}>
                            <Stack flex={1} position="absolute">
                              <Typography variant="subtitle1" component="span">
                                {name}
                              </Typography>
                              <Typography
                                variant="caption"
                                fontWeight={600}
                                color="text.secondary"
                                component="span"
                              >
                                {classGroup?.textValue}
                              </Typography>
                            </Stack>
                          </Fade>
                          <Fade in={isShown} unmountOnExit>
                            <Stack
                              bgcolor="background.neutral"
                              borderRadius={1}
                              p={1}
                              minHeight={48}
                              justifyContent="center"
                            >
                              <Typography
                                variant="body2"
                                sx={{ wordBreak: 'break-word' }}
                              >
                                <LinkRender text={details?.textValue} />
                              </Typography>
                            </Stack>
                          </Fade>
                        </Collapse>
                      </Stack>
                      <IconButton
                        size="small"
                        onClick={(ev) => {
                          ev.preventDefault();
                          setShowText((prev) => ({
                            ...prev,
                            [studentPartyId]: !prev[studentPartyId],
                          }));
                        }}
                      >
                        <EyeIcon />
                      </IconButton>
                    </Stack>
                    <Stack direction="row" flex={1} spacing={0.75}>
                      {tags?.renderedValue}
                    </Stack>
                  </Stack>
                </Card>
              );
            },
          )}
        </Stack>
      )}
    </Card>
  );
}
