import { useQuery } from '@tanstack/react-query';
import {
  type CalendarEventRawFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { calendarKeys } from './keys';

const calendarEventsForEdit = graphql(/* GraphQL */ `
  query calendar_calendarEventsForEdit($filter: CalendarEventRawFilter!) {
    calendar_calendarEventsForEdit(filter: $filter) {
      eventId
      calendarIds
      schedule {
        scheduleId
        startTime
        endTime
        startDate
        endDate
        recurrenceRule
        recurrenceEnum
        allDayEvent
      }
      name
      colour
      description
      source
      sourceId
      tags {
        label
        context
      }
    }
  }
`);

const eventsForEditQuery = (filter: CalendarEventRawFilter) => {
  return {
    queryKey: calendarKeys.eventsForEdit(filter),
    queryFn: async () => gqlClient.request(calendarEventsForEdit, { filter }),
  };
};

export function getCalendarEventsForEdit(filter: CalendarEventRawFilter) {
  return queryClient.fetchQuery(eventsForEditQuery(filter));
}

export function useCalendarEventsForEdit(
  filter: CalendarEventRawFilter,
  enabled = true,
) {
  return useQuery({
    ...eventsForEditQuery(filter),
    select: ({ calendar_calendarEventsForEdit }) =>
      calendar_calendarEventsForEdit,
    enabled,
  });
}

export type ReturnTypeFromUseEventForEdit = UseQueryReturnType<
  typeof useCalendarEventsForEdit
>[number];
