import {
  type NavObjectFunction,
  NavObjectType,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { PrinterIcon } from '@tyro/icons';
import { getTemplates, getVariablesQuery } from '@tyro/templates';
import { redirect } from 'react-router-dom';
import { getTemplatingLabelTemplates } from './api/labels/templates';
import { contextByUrl } from './utils/context-by-url';

const StaffTimetable = lazyWithRetry(() => import('./pages/timetable/staff'));
const PrintYearGroupTimetable = lazyWithRetry(
  () => import('./pages/timetable/year-timetable'),
);
const PrintClassGroupTimetable = lazyWithRetry(
  () => import('./pages/timetable/class-timetable'),
);
const PrintRoomTimetable = lazyWithRetry(
  () => import('./pages/timetable/rooms-timetable'),
);

// Timetables
const PrintTimetableContainer = lazyWithRetry(
  () => import('./components/timetable/print-timetable-container'),
);
const PrintStudentTimetable = lazyWithRetry(
  () => import('./pages/timetable/student-timetable'),
);

// Assessment
const PrintAssessment = lazyWithRetry(() => import('./pages/assessment'));

// User data
const PrintUserDataContainer = lazyWithRetry(
  () => import('./components/user-data/container'),
);
const PrintUserData = lazyWithRetry(() => import('./pages/user-data/print'));

// Labels
const PrintLabelsContainer = lazyWithRetry(
  () => import('./components/labels/container'),
);
const PrintLabels = lazyWithRetry(() => import('./pages/labels/print'));
const PrintLabelTemplates = lazyWithRetry(
  () => import('./pages/labels/templates'),
);

// Letter templates
const PrintLetterTemplatesContainer = lazyWithRetry(
  () => import('./components/letter-templates/container'),
);
const PrintLetterTemplates = lazyWithRetry(
  () => import('./pages/letter-templates/print'),
);

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:management.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'printing',
        title: t('navigation:management.printing.title'),
        icon: <PrinterIcon />,
        children: [
          {
            type: NavObjectType.MenuLink,
            path: 'timetable',
            element: <PrintTimetableContainer />,
            title: t('navigation:management.printing.timetable'),
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission(
                'ps:1:printing_and_exporting:print_timetables',
              ),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./students'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'staff',
                element: <StaffTimetable />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'students',
                element: <PrintStudentTimetable />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'years',
                element: <PrintYearGroupTimetable />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'class',
                element: <PrintClassGroupTimetable />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'rooms',
                element: <PrintRoomTimetable />,
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'assessment',
            element: <PrintAssessment />,
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission(
                'ps:1:printing_and_exporting:print_assessments',
              ),
            title: t('navigation:management.printing.assessment'),
          },
          {
            type: NavObjectType.MenuLink,
            path: 'user-data',
            element: <PrintUserDataContainer />,
            title: t('navigation:management.printing.userData'),
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission('api:templating:read:template'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./students'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':contextType',
                element: <PrintUserData />,
                loader: ({ params }) => {
                  if (params.contextType !== 'students') {
                    throw404Error();
                  }

                  return Promise.all([
                    getVariablesQuery({
                      context: contextByUrl[params.contextType],
                    }),
                  ]);
                },
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'labels',
            element: <PrintLabelsContainer />,
            title: t('navigation:management.printing.labels'),
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission('api:templating:read:template'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./students'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':contextType',
                element: <PrintLabels />,
                loader: ({ params }) => {
                  if (params.contextType !== 'students') {
                    throw404Error();
                  }

                  return Promise.all([
                    getVariablesQuery({
                      context: contextByUrl[params.contextType],
                    }),
                    getTemplatingLabelTemplates({}),
                  ]);
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'templates',
                element: <PrintLabelTemplates />,
                loader: () => getTemplatingLabelTemplates({}),
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'letter-templates',
            element: <PrintLetterTemplatesContainer />,
            title: t('navigation:management.printing.letterTemplates'),
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission('api:templating:read:template'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./students'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':contextType',
                element: <PrintLetterTemplates />,
                loader: ({ params }) => {
                  if (params.contextType !== 'students') {
                    throw404Error();
                  }

                  return getTemplates({});
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
