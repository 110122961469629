import { Forms_LinkEntityType } from '@tyro/api';
import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { getSearchFormsSubmissions } from '@tyro/forms';
import { SchoolBagIcon } from '@tyro/icons';
import { redirect } from 'react-router-dom';
import { getClassAway } from './api/class-away';
import { getSchoolActivityById } from './api/get-school-activities';
import { getSaStudentList } from './api/get-school-activity-students';
import { getLessonsNeedingCover } from './api/lessons-needed-cover';

const SchoolActivityPage = lazyWithRetry(() => import('./pages'));
const CalendarPage = lazyWithRetry(() => import('./pages/calendar'));
const CreateSchoolActivityPage = lazyWithRetry(() => import('./pages/create'));
const EditSchoolActivityPage = lazyWithRetry(() => import('./pages/edit'));
const SchoolActivitiesContainer = lazyWithRetry(
  () => import('./components/school-activities-container'),
);
const CoverRequired = lazyWithRetry(() => import('./pages/cover-required'));
const ClassAway = lazyWithRetry(() => import('./pages/class-away'));
const SchoolActivityAttendancePage = lazyWithRetry(
  () => import('./pages/attendance'),
);
const StudentsList = lazyWithRetry(() => import('./pages/students'));
const StaffList = lazyWithRetry(() => import('./pages/staff'));

const Forms = lazyWithRetry(() => import('./pages/forms'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'school-activity',
        title: t('navigation:general.schoolActivities.title'),
        hasAccess: ({ isStaffUserWithPermission }) =>
          isStaffUserWithPermission(
            'ps:1:school_activity:read_school_activity',
          ),
        icon: <SchoolBagIcon />,
        children: [
          {
            type: NavObjectType.MenuLink,
            title: t('navigation:general.schoolActivities.calendar'),
            path: 'calendar',
            element: <CalendarPage />,
          },
          {
            type: NavObjectType.MenuLink,
            title: t('navigation:general.schoolActivities.schoolActivities'),
            path: 'school-activity',
            element: <SchoolActivityPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'school-activity/create',
            element: <CreateSchoolActivityPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'school-activity/:activityId',
            element: <SchoolActivitiesContainer />,
            loader: ({ params }) => {
              const schoolActivityId = getNumber(params.activityId);

              if (!schoolActivityId) {
                throw404Error();
              }

              return getSchoolActivityById({
                schoolActivityIds: [schoolActivityId],
              });
            },
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./students'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'edit',
                element: <EditSchoolActivityPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'cover-required',
                element: <CoverRequired />,
                loader: ({ params }) => {
                  const schoolActivityId = getNumber(params.activityId);

                  if (!schoolActivityId) {
                    throw404Error();
                  }

                  return getLessonsNeedingCover({ schoolActivityId });
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'class-away',
                element: <ClassAway />,
                loader: ({ params }) => {
                  const schoolActivityId = getNumber(params.activityId);

                  if (!schoolActivityId) {
                    throw404Error();
                  }

                  return getClassAway({ schoolActivityId });
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'forms',
                element: <Forms />,
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission('ps:1:forms:forms_users'),
                loader: ({ params }) => {
                  const { activityId } = params;

                  if (!activityId) {
                    throw404Error();
                  }

                  return getSearchFormsSubmissions({
                    links: [
                      {
                        id: activityId ?? '',
                        type: Forms_LinkEntityType.SchoolActivity,
                      },
                    ],
                  });
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'students',
                element: <StudentsList />,
                loader: ({ params }) => {
                  const schoolActivityId = getNumber(params.activityId);

                  if (!schoolActivityId) {
                    throw404Error();
                  }

                  return getSaStudentList({
                    schoolActivityIds: [schoolActivityId],
                  });
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'staff',
                element: <StaffList />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'attendance',
                element: <SchoolActivityAttendancePage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
